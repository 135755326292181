<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-auto bg-light sticky-top">
                <div class="d-flex flex-sm-column flex-row flex-nowrap bg-light align-items-center sticky-top">
                    <ul
                        class="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto text-center justify-content-between w-100 px-3 align-items-center">
                        <template v-for="(page, idx) in pages" :key="idx">
                            <li class="nav-item">
                                <a href="#" @click="select_page(page.name)" class="nav-link py-3 px-2" title="" data-bs-toggle="tooltip"
                                    data-bs-placement="right" data-bs-original-title="Home">
                                    <i :class="`${page.icon} fs-1`" ></i>
                                </a>
                            </li>                        
                        </template>
                    </ul>
                    <div class="dropdown">
                        <a href="#"
                            class="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle"
                            id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi-person-circle h2"></i>
                        </a>
                        <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">
                            <li><a class="dropdown-item" href="#" @click="this.$store.commit('logout')">Log out</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm p-3 min-vh-100">
                <Header />
                <Loading :component="components" />
                <div class="container-sm text-center overflow-hidden fifty-percent">
                    <div class="row gy-1    ">
                        <template v-for="(component, idx) in components" :key="idx">
                            <template v-if="component.type === 'link'">
                                <div class="col col-md-6 col-sm-11">
                                    <div class="p-4">
                                        <a target="_blank" rel="noopener noreferrer" type="button"
                                            class="btn btn-light custom-button force-button-width" :href="component.href">
                                            <img :src="`img/${component.icon}`" class="icon" /><br />
                                            {{ component.name }}
                                        </a>
                                    </div>
                                </div>
                            </template>
                            <template v-if="component.type === 'looker'">
                                <iframe width="90%" :height="`${component.height}px`" :src="`https://lookerstudio.google.com/embed/reporting/${component.uid}`" frameborder="0" style="border:0" allowfullscreen></iframe>                            
                            </template>
                            <template v-else-if="component.type === 'billboardMap'">
                                <BillboardMap />
                            </template>
                            <template v-else-if="component.type === 'tvMap'">
                                <TvMap />
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import api from '../api'
import Header from '../components/Header.vue'
import BillboardMap from '@/components/BillboardMap.vue'
import TvMap from '@/components/TvMap.vue'
import Loading from '../components/Loading.vue'
export default {
    name: 'home',
    components: {Header, Loading, BillboardMap, TvMap},
    data() {
        return {
            page: null,
            pages: null,
            components: null,
        }
    }, mounted() {
        api.get('api_ui/pages')
            .then(response => { this.pages = response.data['data']; this.page = this.pages[0].name; })
            .then(() => {
                api.get('api_ui/page/'+this.page+'?device=' + this.getDevice())
                    .then(response => { this.components = response.data['data']; })
            })
    }, methods: {
        logout(){
            this.$store.commit('logout')
        },
        select_page(page_name) {
            this.components = null
            api.get('api_ui/page/'+page_name+'?device=' + this.getDevice())
                    .then(response => { this.components = response.data['data']; })
        },
        getDevice() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return 'M'
            } else {
                return 'D'
            }
        }
    }
};
</script>