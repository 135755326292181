<template>
  <div id="app" class="container-md">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
        return {
        }
    },
  components: {
  },
  mounted(){
    this.$store.commit('initialiseStore')
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
