import { createStore } from 'vuex'

export default createStore({
  state: {
    access_token: "",
    refresh_token: "",
    refresh_retries: 0
  },
  getters:{
    access_token(state) {
      return state.access_token
    },
    refresh_token(state) {
      return state.refresh_token
    },
    refresh_retries(state) {
      return state.refresh_retries
    }
  },
  mutations: {
    login(state,data) {
      state.access_token = data.access_token;
      state.refresh_token = data.refresh_token;
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
    },
    logout(state){
      state.access_token ="";
      state.refresh_token ="";
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    },
    initialiseStore(state) {
      if(localStorage.getItem('access_token')){
        state.access_token = localStorage.getItem('access_token');
        state.refresh_token = localStorage.getItem('refresh_token');
       }
    },
  },
  actions: {
  },
  modules: {
  }
})
