<template>
    <div class="container-sm mt-5">
        <Header />
        <div class="row">
            <div class="col col-md-4 col-sm-8 text-center mx-auto">
                <div class="p-4">
                    <div class="input-group mb-3">
                        <input v-model="username" type="text" class="form-control" placeholder="Username"
                            aria-label="Username" aria-describedby="basic-addon1">
                    </div>

                    <div class="input-group mb-3">
                        <input v-model="password" type="password" class="form-control" placeholder="Password"
                            aria-label="Password" aria-describedby="basic-addon2">
                    </div>
                    <button v-if="!clicked" @click="login" type="button" class="btn btn-success">Login</button>
                    <Loading :component="!clicked" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router/index';
import api from '../api'
import Header from '../components/Header.vue'
import Loading from '../components/Loading.vue'

export default {
    name: 'login',
    components: {Header, Loading},
    data() {
        return {
            username: "",
            password: "",
            clicked: false
        }
    }, props: {
    },
    methods: {
        login() {
            this.clicked = true
            api.post('o/token/', 'grant_type=password&username=' + this.username + '&password=' + this.password, { auth: { username: 'front_end', password: '' } })
                .then((response) => {
                    this.$store.commit('login', response.data)
                })
                .then(() => { router.push('/') })
                .catch(() => this.clicked = false)
        }
    }
}
</script>
