<template>
    <div class="container text-center">
        <div class="row pt-2 pb-2">
            <div class="col">
                <datepicker v-model="from" placeholder="from" />
            </div>
            <div class="col">
                <datepicker v-model="to" placeholder="to" />
            </div>
        </div>
        <div class="row pt-2 pb-2">
            <div class="col">
                <multiselect v-model="selectedStatus" :options="statuses" placeholder="Status">
                </multiselect>
            </div>
            <div class="col">
                <multiselect v-model="selectedMarketingSources" :options="marketingSources" :multiple="true"
                    placeholder="Sources">
                </multiselect>
            </div>
            <div class="col">
                <button type="button" class="btn btn-secondary" @click="initMap()">Load</button>
            </div>
            <template v-if="loading">
                <div class="row">
                    <div class="col mx-auto">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
    <div id="map"></div>
</template>
<script>


var map
import api from '@/api'
import Datepicker from 'vuejs3-datepicker';
import Multiselect from 'vue-multiselect'

export default {
    name: 'billboardMap',
    components: { Datepicker, Multiselect },
    props: [],
    data() {
        return {
            billboards: null,
            counties: null,
            leads: null,
            loading: false,
            from: null,
            to: null,
            channels: [],
            selectedChannels: [],
            elements: [],
            statuses: ['Leads', 'Wanted', 'Signed'],
            selectedStatus: [],
            marketingSources: ['marketingsourceOnline', 'Previous Client', 'Referral', 'Television', 'Billboard', 'Social Media', 'Unknown', 'Radio', 'Phonebook', 'Web Search - Branded', 'Web Search - Non-branded', 'Web Search', 'PPC Brand', 'PPC Non-Brand', 'Website', '"Charitable Events, Sponsorships, PR"', 'LSA Jackson ', 'LSA Hattiesburg', 'LSA Tupelo', 'Precision Targeting', 'LSA Meridian', 'Referral - Friend', 'Saw us at an event', 'TV Lead Gen', 'Referral - Attorney', 'Quintessa Marketing Lead ', 'Referral - Doctor'],
            selectedMarketingSources: null
        }
    },
    mounted() {
        this.selectedStatus = this.statuses[0]
        this.to = new Date();
        this.from = new Date(new Date().setDate(this.to.getDate() - 30));
        map = new window.google.maps.Map(document.getElementById("map"), {
            zoom: 7,
            center: { lat: 33.0358842, lng: -89.6072731 },
            mapId: "rsa-47130841734028",
        });
    },
    methods: {
        isLoaded() {
            if (this.billboards && this.counties && this.leads) {
                this.loaded = true
            }
        },
        initMap() {
            this.loading = true
            fetch('us-county-boundaries.geojson')
                .then((response) => response.json())
                .then((json) => { this.counties = json; this.loadcounties() })
            fetch('Billboards.geojson')
                .then((response) => response.json())
                .then((json) => { this.billboards = json; this.loadbillboards() })
            api.post('api_ui/billboard', { args: { 
                    from: this.from.toISOString().slice(0, 10), 
                    to: this.to.toISOString().slice(0, 10),
                    status: this.selectedStatus,
                    selectedMarketingSources: this.selectedMarketingSources
                } })
                .then(response => { this.leads = response.data['data']; this.loadleads() })
                .then(() => this.loading = false)

        },
        loadcounties() {
            this.counties['features'].forEach(coord => {
                let coords = []
                for (let i = 0; i < coord.geometry.coordinates[0].length; i++) {
                    const element = coord.geometry.coordinates[0][i];
                    coords.push({ lat: element[1], lng: element[0] })
                }


                const c = new window.google.maps.Polygon({
                    paths: coords,
                    strokeColor: "#423d3d",
                    strokeOpacity: 0.5,
                    strokeWeight: 1,
                    // fillColor: "#FFC107",
                    fillOpacity: 0.1,
                });
                c.setMap(map)
            });
            this.isLoaded()
        },
        loadbillboards() {
            this.billboards['features'].forEach(coord => {
                let element = coord.geometry.coordinates;
                let pinBackground = new window.google.maps.marker.PinElement({
                    scale: 0.5,
                    background: "#FBBC04",
                    borderColor: "#137333",
                });

                new window.google.maps.marker.AdvancedMarkerElement({
                    map,

                    position: { lat: element[1], lng: element[0] },
                    content: pinBackground.element,
                });
            });
            this.isLoaded()
        },
        loadleads() {
            this.leads.forEach(lead => {
                let marker = new window.google.maps.Circle({
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.9,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.5,
                    title: lead.count + ' lead(s)',
                    map,
                    center: { lat: lead.lat, lng: lead.lng },
                    radius: Math.sqrt(lead.count) * 3500,
                });
                window.google.maps.event.addListener(marker, 'mouseover', function () {
                    this.getMap().getDiv().setAttribute('title', this.get('title'));
                });

                window.google.maps.event.addListener(marker, 'mouseout', function () {
                    this.getMap().getDiv().removeAttribute('title');
                });
            })
            this.isLoaded()
        },
        loadChannels(json) {
            for (let index = 0; index < json.features.length; index++) {
                const element = json.features[index];
                element['properties']['STATION'].split(',').forEach(element => {
                    if (this.channels.indexOf(element) === -1 && element != "") {
                        this.channels.push(element);
                    }
                });
            }
        }
    },
    watch: {
    }
};
</script>

