<template>
    <template v-if="!component">
        <div class="row">
            <div class="col mx-auto">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </template>
</template>

<script>

export default {
    name: 'header',
    props: ['component'],
    data() {
        return {
        }
    },
    methods: {}
};
</script>
