<template>
    <div class="container text-center">
        <div class="row pt-2 pb-2">
            <div class="col">
                <datepicker v-model="from" placeholder="from" />
            </div>
            <div class="col">
                <datepicker v-model="to" placeholder="to" />
            </div>
        </div>
        <div class="row pt-2 pb-2">
            <div class="col">
                <multiselect v-model="selectedChannels" :options="channels" :multiple="true" placeholder="Channels">
                </multiselect>
            </div>
            <div class="col">
                <multiselect v-model="selectedStatus" :options="statuses" placeholder="Status">
                </multiselect>
            </div>
            <div class="col">
                <multiselect v-model="selectedMarketingSources" :options="marketingSources" :multiple="true"
                    placeholder="Sources">
                </multiselect>
            </div>
            <div class="col">
                <button type="button" class="btn btn-secondary" @click="initMap()">Load</button>
            </div>
            <template v-if="loading">
                <div class="row">
                    <div class="col mx-auto">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
    <div id="map"></div>
</template>
<script>


var map
import api from '@/api'
import Datepicker from 'vuejs3-datepicker';
import Multiselect from 'vue-multiselect'
export default {
    name: 'tvboardMap',
    components: { Datepicker, Multiselect },
    props: [],
    info: null,
    data() {
        return {
            tvs: null,
            counties: null,
            leads: null,
            loading: false,
            from: null,
            to: null,
            channels: [],
            selectedChannels: [],
            elements: [],
            statuses: ['Leads', 'Wanted', 'Signed'],
            selectedStatus: [],
            marketingSources: ['marketingsourceOnline', 'Previous Client', 'Referral', 'Television', 'Billboard', 'Social Media', 'Unknown', 'Radio', 'Phonebook', 'Web Search - Branded', 'Web Search - Non-branded', 'Web Search', 'PPC Brand', 'PPC Non-Brand', 'Website', '"Charitable Events, Sponsorships, PR"', 'LSA Jackson ', 'LSA Hattiesburg', 'LSA Tupelo', 'Precision Targeting', 'LSA Meridian', 'Referral - Friend', 'Saw us at an event', 'TV Lead Gen', 'Referral - Attorney', 'Quintessa Marketing Lead ', 'Referral - Doctor'],
            selectedMarketingSources: null
        }
    },
    mounted() {
        this.selectedStatus = this.statuses[0]
        this.to = new Date();
        this.from = new Date(new Date().setDate(this.to.getDate() - 30));
        map = new window.google.maps.Map(document.getElementById("map"), {
            zoom: 7,
            center: { lat: 33.0358842, lng: -89.6072731 },
            mapId: "rsa-47130841734029",
        });
        fetch('Tvs.geojson')
            .then((response) => response.json())
            .then((json) => { this.loadChannels(json); this.tvs = json; })
    },
    methods: {
        isLoaded() {
            if (this.tvs && this.leads) {
                this.loading = false
            }
        },
        initMap() {
            this.loading = true
            this.elements.forEach(e => {
                e.setMap(null)
            })
            this.elements = []
            this.loadtvs()
            api.post('api_ui/billboard', {
                args: {
                    from: this.from.toISOString().slice(0, 10),
                    to: this.to.toISOString().slice(0, 10),
                    status: this.selectedStatus,
                    selectedMarketingSources: this.selectedMarketingSources
                }
            })
                .then(response => { this.leads = response.data['data']; this.loadleads() })
                .then(() => this.loading = false)

        },
        loadtvs() {
            this.tvs['features'].forEach(coord => {
                let coords = []
                let isSelectedChannel = false
                if (this.selectedChannels.length > 0) {
                    coord.properties.STATION.split(',').forEach(element => {
                        if (this.selectedChannels.indexOf(element) !== -1) {
                            isSelectedChannel = true
                        }
                    })
                } else {
                    isSelectedChannel = true
                }

                if (isSelectedChannel) {
                    for (let i = 0; i < coord.geometry.coordinates[0].length; i++) {
                        const element = coord.geometry.coordinates[0][i];
                        coords.push({ lat: element[1], lng: element[0] })
                    }

                    const infoWindow = new window.google.maps.InfoWindow();
                    const c = new window.google.maps.Polygon({
                        paths: coords,
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.5,
                        strokeWeight: 1,
                        fillColor: "#9bf542",
                        fillOpacity: 0.1,
                    });
                    window.google.maps.event.addListener(c, 'mouseover', function (e) {
                        if (this.info) {
                            this.info.setPosition(null);
                            this.info.open(null);
                        }
                        infoWindow.setContent(coord.properties.NAME + ' - ' + coord.properties.STATION);
                        var latLng = e.latLng;
                        infoWindow.setPosition(latLng);
                        infoWindow.open(map);
                        this.info = infoWindow
                    });
                    window.google.maps.event.addListener(c, 'mouseout', function () {
                        infoWindow.setPosition(null);
                        infoWindow.open(null);
                        this.info = null
                    });
                    c.setMap(map)
                    this.elements.push(c)
                }

            });
            this.isLoaded()
        },
        loadleads() {
            this.leads.forEach(lead => {
                const a = new window.google.maps.Circle({
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.9,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.5,
                    tag: 'aaa',
                    map,
                    center: { lat: lead.lat, lng: lead.lng },
                    radius: Math.sqrt(lead.count) * 3500,
                });
                const infoLeads = new window.google.maps.InfoWindow();
                window.google.maps.event.addListener(a, 'mouseover', function (e) {
                    if (this.info) {
                        this.info.setPosition(null);
                        this.info.open(null);
                    }
                    infoLeads.setContent(' ' + lead.count + ' ');
                    var latLng = e.latLng;
                    infoLeads.setPosition(latLng);
                    infoLeads.open(map);
                    this.info = infoLeads
                });
                window.google.maps.event.addListener(a, 'mouseout', function () {
                    infoLeads.setPosition(null);
                    infoLeads.open(null);
                    this.info = null
                });
                this.elements.push(a)

            })
            this.isLoaded()
        },
        loadChannels(json) {
            for (let index = 0; index < json.features.length; index++) {
                const element = json.features[index];
                element['properties']['STATION'].split(',').forEach(element => {
                    if (this.channels.indexOf(element) === -1 && element != "") {
                        this.channels.push(element);
                    }
                });
            }
        }
    },
    watch: {
    }
};
</script>

