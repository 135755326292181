<template>
    <div class="container-sm text-center">
        <hr class="mt-1 mb-1" />
    </div>
    <div class="container-sm text-center">
        <img src="eclipse-logo-tiny.png" style="width: 25%;" />
    </div>
    <div class="container-sm text-center pb-4">
        <hr class="" />
    </div>
</template>

<script>

export default {
    name: 'header',
    props: [],
    data() {
        return {
        }
    },
    methods: {}
};
</script>
